@import (reference) "./../bower_components/bootstrap/less/mixins.less";
@import (reference) "./../bower_components/bootstrap/less/variables.less";
@import (reference) "./../bower_components/bootstrap/less/type.less";
@import (reference) "./../bower_components/bootstrap/less/labels.less";
@import (reference) "./../bower_components/bootstrap/less/forms.less";

body {
//  font-family: 'Roboto', 'Helvetica Neue', Helvetica, Arial, sans-serif;
  padding-top: 50px;
}

h1, h2, h3, h4, h5, h6 {
//  font-family: 'Roboto', 'Lucida Grande', 'Lucida Sans Unicode', 'Geneva', 'Verdana', sans-serif;
  font-weight: 700;
}

nav {
  &.navbar {
    margin-bottom: 0px;
  }
  div.navbar-header {
    a.navbar-brand {
      padding:0;
      img {
        height: 50px;
      }
    }
  }
  .navbar-nav>li>.dropdown-menu {
    margin-top: 2px;
  }
}

.social-login {
  position: relative;
  border-top: 1px solid #adadad;
  margin: 10px 5px 5px 0;
  padding-top: 15px;
  &:before {
    content: ' or ';
    position: absolute;
    top: 0;
    left: 50%;
    margin: -10px 0 0 -5px;
    padding: 0 5px;
    background-color: #FFFFFF;
    font-weight: bold;
  }
}

ul.pagination {
  li {
    a {
      cursor: pointer;
      &.active {
        cursor: default;
      }
    }
  }
}

ul.nav-tabs {
  li {
    a {
      cursor: pointer;
    }
  }
}

span.star {
  font-family:FontAwesome;
  font-weight:normal;
  font-style:normal;
  display:inline-block;
  &:before {
    content: "\f006";
    padding-right: 5px;
    color: #999;
  }
  &.starred {
    &:before {
      content: "\f005";
      color: #f5e77d;
    }
  }
  &:hover {
    &:before {
      color: #ed9c28;
    }
  }
}

span.eye {
  font-family:FontAwesome;
  font-weight:normal;
  font-style:normal;
  display:inline-block;
  &:before {
    content: "\f06e";
    padding-right: 5px;
  }
  &.slashed {
    &:before {
      content: "\f070";
    }
  }
  &:hover {
    &:before {
      color: #ed9c28;
    }
  }
}

.btn-default {
  .badge {
    background-color: @badge-bg;
  }
}

th, td {
  &.phone {
    width: 105px;
  }
  &.addy {
    width: 315px;
  }
  &.contact {
    width: 270px;
  }
  &.money {
    width: 120px;
    text-align: right;
  }
  &.total {
    font-weight: bold;
    border-top: 1px solid #000000;
  }
  &.int {
    width: 60px;
    text-align: right;
  }
  &.shortDate {
    width: 120px;
    text-align: right;
  }
}

.cancelled {
  a, .cancellable {
    .text-danger;
    text-decoration: line-through;
  }
}

.master-toolbar {
  margin-bottom: (@line-height-computed / 2);
}

.section-header {
  .btn-toolbar {
    float: right !important;
    label {
      display: inline-block;
      padding: @padding-base-vertical @padding-base-horizontal;
      font-size: @font-size-base;
      font-weight: bold;
    }
  }
}


button {
  &.btn-back {
    margin-bottom: 4px;
    margin-top: -4px;
  }
}

.status-media-object {
  width: 50px;
  padding-top: (@line-height-computed / 2);
  padding-bottom: (@line-height-computed / 2);

  border: 1px solid @gray-lighter;
}

.cd-navd {
  .text-right;
  p& {
    .form-control-static;
  }
}

.photo {
  min-height: 280px;
  position: relative;
  &.wants-photo {
    border: 5px dashed @gray;
    background-color: @gray-lighter;
    &.hot {
      border-color: @brand-success;
    }
  }
  .photo-controls {
    position: absolute;
    right: 0;
    top: 0;
    padding: @padding-small-vertical;
    .clickable {
      cursor: pointer;
    }
    .danger {
      color: @brand-danger;
      &:hover,
      &:focus,
      &:active {
        color: darken(@brand-danger, 8%);
      }
      .border {
        color: darken(@brand-danger, 5%);
        &:hover,
        &:focus,
        &:active {
          color: darken(@brand-danger, 12%);
        }
      }
    }
  }
  img {
    width: 100%;
    &.missing {
      height: 280px;
    }
  }
}


@media print {
  body {
    margin: 0;
   	padding: 0;
  }
  *, *:before, *:after {
  	box-sizing: border-box;
  }

  nav, button, .btn, .no-print {
    display: none;
  }
  .show-print {
    display: block !important;
  }

  .letter {
    @page {
      size: letter;
      margin: 0pt;
    }
  }

  .legal {
    @page {
      size: legal;
      margin: 0pt;
    }
  }
}


.job-status {
  .label;
  .label-default;

  text-transform: uppercase;

  &.paid {
    .label-success;
  }
  &.unpaid {
    .label-danger;
  }
  &.uninvoiced {
    .label-danger;
  }
  &.no-charge {
    .label-info;
  }
  h2 & {
    font-size: 11px;
    vertical-align: middle;
  }
}

div.busy {
  position: absolute;
  width: 100%;
  height: 100%;
  min-height: 300px;
  text-align: center;
  vertical-align: middle;
  background-color: fade(@gray-lighter, 50%);
  p {
    vertical-align: middle;
    margin-top: 140px;
  }
}
